$(function () {
	$('#contact-form').validate({
		submitHandler: function (form) {
			$(form).find('.form__submit').addClass('is-disabled');

			const data = new FormData();
			data.append('name', $(form).find('[name="cf-name"]').val());
			data.append('phone', $(form).find('[name="cf-phone"]').val());
			data.append('message', $(form).find('[name="cf-message"]').val());

			$.ajax({
				method: 'POST',
				url: $(form).attr('action'),
				enctype: 'multipart/form-data',
				cache: false,
				contentType: false,
				processData: false,
				data: data,
				success: function (data) {
					data = JSON.parse(data);
					$(form).find('.form__response').removeClass('is-error').text(data.response);
					$(form)[0].reset();
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$(form).find('.form__response').addClass('is-error').text(errorThrown);
				},
				complete: function () {
					$(form).find('.form__submit').removeClass('is-disabled');
					$(form).find('.form__response').removeClass('hide');
					setTimeout(() => $(form).find('.form__response').removeClass('is-error').addClass('hide').text(''), 5000);
				},
			});
		},
	});
});